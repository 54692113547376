import React, { useEffect } from "react"
import Layout from "../components/Layout/Layout"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import HubSpotForm from "../components/HubSpotForm"
import HeaderImage from "../assets/img/new/tlo-serch-01.webp"
import { devMode } from "../components/Variables"

import "../../i18n"
import { useTranslation } from "react-i18next"
import i18n from "i18next"

const Kontakt = () => {
  const { t } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage("en")
  }, [])

  return (
    <Layout
      seo={{
        title: "Free SEO consultation • Fill in the form • WeNet.pl",
        description:
          "Get a free SEO consultation and increase the page rank of your website on Google • Fill in the application form",
        hreflangs: {
          pl: "kontakt/",
          en: "en/contact/",
          de: "de/kontakt/",
        },
        schema: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": "/",
                name: "Home",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": "/en/free-seo-consultation/",
                name: t("seo-title-free-seo-consultation"),
              },
            },
          ],
        },
      }}
    >
      <div className="hero-header-page" style={{ backgroundImage: `url("${HeaderImage}")` }}>
        <div className="breadcrumbs-wrapper">
          <Breadcrumbs
            path={[
              { url: t("link-url-home"), text: t("link-text-home") },
              { url: t("link-url-contact"), text: t("link-text-contact") },
            ]}
          />
        </div>
        <h1 className="title text-center">Free SEO consultation</h1>
      </div>

      <section className="standard-section contact-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-md-10 offset-md-1 offset-lg-0 col-12">
              <p className="text-blue font-18" style={{ marginTop: "30px" }}>
                Do you want to fix errors on your website and increase your position in Google results? Fill in the
                form. Our consultant will contact you and audit your website for free.
                <br />
                <br />
              </p>
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <h2>Application form</h2>
              <HubSpotForm
                formId={
                  devMode === "true" ? "09c3daf0-722b-4813-90a7-1a30abb01bf8" : "8e4f56f0-e18f-40fa-81af-2d7a7fcbc89e"
                }
                formType="consultation"
              />
            </div>
            <div className="col-xl-4 offset-xl-1 col-lg-6 col-md-10 offset-md-1 offset-lg-0">
              <p className="text-blue font-18">
                With SEO consultation, you gain guidance for optimisation, and the introduction of adjustments will
                result in:
                <br />
              </p>
              <ul style={{ marginBottom: "30px" }}>
                <li>
                  a well-optimised website that stays on track – in some cases, errors can completely disqualify a
                  website,
                </li>
                <li>
                  saving time and money – a working, quality website is guaranteed to reduce costs and further SEO and
                  SEM operations as much as possible,
                </li>
                <li>
                  higher positioning and increased traffic – optimised website means faster indexing by Google robots,
                </li>
                <li>
                  effective access to users, your potential customers – a valuable website also benefits users, who get
                  the information they are looking for more quickly and efficiently.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Kontakt
